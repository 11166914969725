import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MiscellaneousService {

  visible: boolean;

  constructor() {
    this.visible = true;
  }

  loaderSubject: Subject<object> = new Subject<object>();
  scrollToView: Subject<string> = new Subject<string>();
  showMenuSubject: Subject<string> = new Subject<string>();

  hide() { this.visible = false; }

  show() { this.visible = true; }



  showLoader(message = '') {
    this.loaderSubject.next({ visibility: true, message: message });
  }

  showLoadernobg(message = '') {
    this.loaderSubject.next({ visibility: true, nobg: true, message: message });
  }


  hideLoader() {
    this.loaderSubject.next({ visibility: false, message: null });
  }

  initializeMathJax(mathjax: any) {
    return new Observable(obserser => {
      eval('MathJax.Hub.Queue(["Typeset",MathJax.Hub, mathjax])');
      eval('MathJax.Hub.Queue(["Typeset",MathJax.Hub, mathjax])');
      obserser.next();
    });
  }

}
