import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }

  handleError(error: HttpErrorResponse) {

    const errorObj = {
      statusCode: 0,
      message: ''
    }
    // A client-side or network error occurred. Handle it accordingly.
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error);
    }

    // Server Errors
    if (error['error']) {

      // Non Field Error
      if (error['error']['non_field_errors']) {
        errorObj.statusCode = error.status
        errorObj.message = error['error']['non_field_errors'][0]
      } else if (error['error']) {
        errorObj.statusCode = error.status
        errorObj.message = error['error']
      }
    }

    return throwError(errorObj);
  };


  getFormErrors(error: object): object {

    const errors = {};

    if (error) {
      if (error['first_name']) {
        errors['first_name'] = error['first_name']
      }
      if (error['last_name']) {
        errors['last_name'] = error['last_name']
      }
      if (error['contact_number']) {
        errors['contact_number'] = error['contact_number']
      }
      if (error['password']) {
        errors['password'] = error['password']
      }
      if (error['student_group']) {
        errors['student_group'] = error['student_group']
      }
      if (error['coupon_code']) {
        errors['coupon_code'] = error['coupon_code']
      }

      return errors;
    }
    return null;
  }
}
