import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstantsService } from 'src/app/config/constants.service';
import { ErrorHandlerService } from 'src/app/core/http/error-handler.service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class S3signedurlService {

  constructor(
    private http: HttpClient,
    private constsvc: ConstantsService,
    private errorHandler: ErrorHandlerService,
    private cookie: CookieService,
  ) { }

  getHeaderOption(): any {
    const token = this.cookie.get('_l_a_t');
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }).set('Access-Control-Allow-Origin', '*')
    };
  }

  uploadVideoAWSS3(id, data: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*'
      })
    };
    return this.http.put(`${this.constsvc.updateVideoApiUrl}/${id}`, data, httpOptions)
      .pipe(
        catchError(this.errorHandler.handleError)
      );
  }


  getpresignedurls(item) {
    return this.http.get(`${this.constsvc.getSignedurlApiUrl}` + item);
  }

  getpresignedBookurls(url) {
    return this.http.get(`${this.constsvc.getSignedurlBookApiUrl}` + url);
  }

  uploadfileAWSS3(fileuploadurl, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
    }).set('Access-Control-Allow-Origin', '*',);

    // .set(InterceptorSkipHeader, '*', );

    // 'Access-Control-Allow-Origin', '*'
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      data,
      {
        headers: headers,
        reportProgress: true,
      }
    );
    return this.http.request(req);
  }
/// Donot delete below
  // uploadvideoAWSS3(fileuploadurl, data: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'video/mp4'
  //   }).set(InterceptorSkipHeader, '');
  //   const req = new HttpRequest(
  //     'PUT',
  //     fileuploadurl,
  //     data,
  //     {
  //       headers: headers,
  //       reportProgress: true,
  //     }
  //   );
  //   return this.http.request(req);
  // }

  uploadVideosAWSS3(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'video/mp4'
    }).set(InterceptorSkipHeader, '');
    const req = new HttpRequest(
      'PUT',
      data,
      {
        headers: headers,
        reportProgress: true,
      }
    );
    return this.http.request(req);
  }
}
