import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MathjaxComponent } from 'src/app/modules/super-admin/components/view-update-questions/mathjax/mathjax.component';

@NgModule({
  declarations: [MathjaxComponent],
  imports: [CommonModule],
  exports:[MathjaxComponent]
})
export class SharedModule {}
