import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';


@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor(
    public cookie: CookieService
  ) {
  }

  get authTokenHeaders() {
    return {
      headers: new HttpHeaders({
        Authorization: `Token ${this.cookie.get('a_t')}`,
        'Content-Type': 'application/json; charset=utf-8'
      }).set(InterceptorSkipHeader, '')
    };
  }

  get authWithoutTokenHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }).set(InterceptorSkipHeader, '')
    };
  }

  baseModuleUrl = '/student/assessment';
  getUpdateProfileUrl = `${environment.BASE_URL_CMS}shortprofile/`;
  socialLogin = `${environment.BASE_URL_CMS}users/sociallogin/`;
  testsummayApiUrl = `${environment.BASE_URL_CMS}dashboard/testsummay/`;
  testDetailApiUrl = `${environment.BASE_URL_CMS}packages/?package_id=`;
  enrolledpackagesApiUrl = `${environment.BASE_URL_CMS}userenrolledpackages/`;
  assessmentpaperTestAnalysisApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_test_analysis/`;
  assessmentpaperReportApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_report/`;
  mockpaperReportApiUrl = `${environment.BASE_URL_CMS}mockpaper_report/`;
  mockpaperReportOverallApiUrl = `${environment.BASE_URL_CMS}mockpaper_report_overall/`;
  assessmentpaperChapterReportApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_chapter_report/`;
  assessmentpaperTestComparisonScoreApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_test_comparison_score/`;
  assessmentpaperTestComparisonAttemptedApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_test_comparison_attempted/`;
  assessmentpaperTestComparisonAccuracyApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_test_comparison_accuracy/`;
  assessmentpaperStudentRankApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_student_rank/`;
  instructionApiUrl = `${environment.BASE_URL_CMS}instruction/`;
  mockpaperSolutionApiUrl = `${environment.BASE_URL_CMS}mockpaper_solution/`;
  assessmentpaperSolutionApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_solution/`;
  completeTestmockpaperQuestionsApiUrl = `${environment.BASE_URL_CMS}mockpaper_questions/`;
  // completeTestAssessmentpaperQuestionsApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_questions/`;
  completeTestAssessmentpaperQuestionsApiUrl = `${environment.BASE_URL_CMS}exam/assesmentpaperquestion/`;
  postAnswerApiUrl = `${environment.BASE_URL_CMS}exam/postAnswer/`;
  postAnswerImageApiUrl = `${environment.BASE_URL_CMS}post_answer_image/`;
  assessmentpaperQuestionsApiUrl = `${environment.BASE_URL_CMS}assessmentpaper_questions/`;
  mockpaperQuestionsApiUrl = `${environment.BASE_URL_CMS}mockpaper_questions/`;



  LOGIN_EXPIRY_TIME = 7;
  COOKIE_EXPIREY_TIME = 7;

}
