import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchChildService {

  private memoryGameModal: BehaviorSubject<any> = new BehaviorSubject('');
  private memoryGameArray: BehaviorSubject<any> = new BehaviorSubject([]);

  memoryGameId = this.memoryGameModal.asObservable();
  memoryGameData = this.memoryGameArray.asObservable();

  constructor() { }

  updateMemoryGameId(id) {
    this.memoryGameModal.next(id);
  }

  updateMemoryGameData(data) {
    this.memoryGameArray.next(data);
  }
}
