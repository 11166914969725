import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MiscellaneousService {

  constructor(
  ) {
  }

  showLoaderSubject: Subject<object> = new Subject<object>();
  /**
   * Intialize mathjax configurations
   */
  initializeMathJax(mathjax: any) {
    return new Observable(obserser => {
      eval('MathJax.Hub.Queue(["Typeset",MathJax.Hub, mathjax])');
      eval('MathJax.Hub.Queue(["Typeset",MathJax.Hub, mathjax])');
      obserser.next();
    });
  }

  showLoader() {
    this.showLoaderSubject.next({ visibility: true });
  }

  hideLoader() {
    this.showLoaderSubject.next({ visibility: false });
  }

}
